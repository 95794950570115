/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^1[0-9]{10}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/* 是否身份证号码*/
export function validateIdNo(s) {
	 
  const reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
  
  return reg.test(s)

}

/* 是否是金额*/
export function validateIsMoney(s) {
	 
  const reg = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
  
  return reg.test(s)

}
export function timeConvert(timestamp,num){//num:0 YYYY-MM-DD  num:1  YYYY-MM-DD hh:mm:ss // timestamp:时间戳 
  timestamp = timestamp+'';
  timestamp = timestamp.length==10?timestamp*1000:timestamp;
  var date = new Date(timestamp);
  var y = date.getFullYear();  
  var m = date.getMonth();  
  m = m < 10 ? ('0' + m) : m;  
  var d = date.getDate();  
  d = d < 10 ? ('0' + d) : d;  
  var h = date.getHours();
  h = h < 10 ? ('0' + h) : h;
  var minute = date.getMinutes();
  var second = date.getSeconds();
  minute = minute < 10 ? ('0' + minute) : minute;  
  second = second < 10 ? ('0' + second) : second; 
  if(num==0){
      return y + '-' + m + '-' + d;  
  }else if (num==1){
      return y + '-' + m + '-' + d +' '+ h +':'+ minute +':' + second;  
  }else{
   
    return  new Date(y, m, d, h, minute);  
  }
}
