<template>
  <section>
    <!-- <el-row> -->
      <el-form :inline="true"
               size="mini"
               style="padding-left:10px">
          <!--<el-form-item label="部门">
      <el-input v-model="dptnameinput"
                placeholder="部门"
                size="mini"
                style="width: 70%;"
                clearable></el-input>
    </el-form-item>-->
          <el-form-item label="部门">
              <el-input placeholder="输入部门名称"
                        size="mini"
                style="width: 70%;"
                        v-model="filterText">
              </el-input>
          </el-form-item>
          <el-form-item label="账号"
                        style="margin-left: -50px;">
              <el-input v-model="usernameinput"
                        placeholder="账号"
                        size="mini"
                        style="width: 50%;"
                        clearable></el-input>
          </el-form-item>
          <el-form-item label="名称"
                        style="margin-left: -90px;">
              <el-input v-model="nameinput"
                        placeholder="人员名称"
                        size="mini"
                        style="width: 50%;"
                        clearable></el-input>
          </el-form-item>
          <el-form-item style="margin-left: -90px;">
              <el-radio-group v-model="poststatus"
                              size="mini"
                              v-show="alluserradioshow">
                  <el-radio-button label="1"
                                   :checked="true">有职务</el-radio-button>
                  <el-radio-button label="2">无职务</el-radio-button>
                  <el-radio-button label="0">全部</el-radio-button>
              </el-radio-group>
          </el-form-item>
          <el-form-item>
              <el-button type="primary"
                         size="mini"
                         plain
                         @click="postsearch">查询</el-button>
          </el-form-item>
      </el-form>
    <!-- </el-row> -->
    <!--组织维护树形工具条-->
    <div id="userdiv">
      <el-col :span="7"
              style="padding-left:10px;padding-right:10px;">
        <el-divider>组织结构</el-divider>
        <div id="dpttree">
            <!--<el-tree :load="loadDptNode"
                     :props="RoleProps"
                     lazy
                     @node-click="PostClick"
                     v-if="datatree"
                     highlight-current
                     :render-content="renderContent"></el-tree>-->
            <el-tree :data="departmentsearchdata"
                     :props="defaultProps"
                     :filter-node-method="filterNode"
                     @node-click="nodeClickPost"
                     :render-content="renderContent"
                     ref="tree">
            </el-tree>
        </div>
      </el-col>
      <!--待选人员列表-->
      <el-col :span="10"
              style="padding-left:10px;padding-right:10px;">
          <!-- <el-card> -->
          <el-divider>人员</el-divider>
          <div id="usertable">
              <!--<el-table :data="userform"
                  highlight-current-row
                  @row-dblclick="addNewUser">
          <el-table-column type="index"
                           width="40px"></el-table-column>
          <el-table-column label="账号"
                           prop="UserName"
                           width
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="姓名"
                           prop="UserNameClaim"
                           width
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="部门"
                           prop="DepartmentName"
                           width
                           show-overflow-tooltip></el-table-column>
          <el-table-column label="选择"
                           width="50px">
            <template scope="scope">
              <el-button type="success"
                         icon="el-icon-right"
                         size="mini"
                         circle
                         @click="addNewUser(scope.row)"></el-button>
            </template>
          </el-table-column>
        </el-table>-->
              <el-table :data="userform"
                        highlight-current-row
                        @row-dblclick="addNewUser">
                  <el-table-column type="index"
                                   width="40px"></el-table-column>
                  <el-table-column label="账号"
                                   prop="username"
                                   width
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column label="姓名"
                                   prop="name"
                                   width
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column label="部门"
                                   prop="deptName"
                                   width
                                   show-overflow-tooltip></el-table-column>
                  <el-table-column label="选择"
                                   width="50px">
                      <template scope="scope">
                          <el-button type="success"
                                     icon="el-icon-right"
                                     size="mini"
                                     circle
                                     @click="addNewUser(scope.row)"></el-button>
                      </template>
                  </el-table-column>
              </el-table>
          </div>
          <!-- 分页器 -->
          <el-pagination :small="true"
                         align="center"
                         @current-change="handleCurrentChange"
                         :current-page="currentPage"
                         :page-size="pageSize"
                         layout="prev, pager, next"
                         :page-count="total"
                         :total="totaldata"
                         v-show="postshow"></el-pagination>
          
          <!--分页器2-->
          <el-pagination :small="true"
                         align="center"
                         @current-change="handleCurrentChange2"
                         :current-page="currentPage2"
                         :page-size="pageSize2"
                         layout="prev, pager, next"
                         :page-count="total2"
                         :total="totaldata2"
                         v-show="searchshow"></el-pagination>
          <!-- </el-card> -->
      </el-col>
      <!--已选人员列表-->
      <el-col :span="7"
              style="padding-left:10px;padding-right:10px;">
        <!-- <el-card> -->
        <el-divider>已选人员</el-divider>
        <div id="chooseusertable">
            <el-table :data="gotuserform"
                      highlight-current-row
                      @row-dblclick="deleteNewUser">
                <el-table-column type="index"
                                 width="40px"></el-table-column>
                <el-table-column label="账号"
                                 prop="UserName"
                                 width
                                 show-overflow-tooltip></el-table-column>
                <el-table-column label="姓名"
                                 prop="UserNameClaim"
                                 width
                                 show-overflow-tooltip></el-table-column>
                <el-table-column label="移除"
                                 width="50px">
                    <template scope="scope">
                        <el-button type="danger"
                                   icon="el-icon-back"
                                   size="mini"
                                   circle
                                   @click="deleteNewUser(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!-- </el-card> -->
      </el-col>
    </div>
    <div slot="footer"
         style="text-align: center;">
      <el-button type="primary"
                 size="mini"
                 plain
                 @click="addNewSubmit">提交</el-button>
    </div>
    <!-- </el-dialog> -->
  </section>
</template>
<script>
    import { GetChildrenList, GetDepartmentTypes, GetDepartmentPostUserList, GetUserChooseList, GetPostUserPrincipalList, GetPostUserViewPageByDto, GetManagePost } from '../api/hr';
export default {
  name: "Userchoosetool",
  data () {
      return {
          filterText: '',
          departmentsearchdata:[],
      //   dialogWidth: 0,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 5, // 每页的数据条数
      page: 1,
      currentPage2: 1, // 当前页码
      total2: null, // 总条数
      totaldata2: null,
      pageSize2: 5, // 每页的数据条数
      page2: 1,
          node2: null,    //暂存选中node信息
          node21: null,    //暂存选中node信息
      alluserradioshow: false,
      deptname: null,   //双向绑定部门name
      dptnameinput: null,
      usernameinput: null,
      nameinput: null,
      poststatus: '1',
      postshow: true,
      searchshow: false,
      datatree: false,   //树重新加载
      userform: [],    //初始化待选名单列表
      gotuserform: [],  //初始化已选人员名单
      newcontent: null,  //暂存新增人员内容
      TypeIdModules: [],      //部门类型集合
      RoleProps: {
        label: 'Name',
        isLeaf: 'Leaf'
        },
        defaultProps: {
            children: 'children',
            label: 'label'
        },
    }
  },
  props: ["limit", "data", "all", "single"], //接受父组件传值
  methods: {
            filterNode(value, data) {
                if (!value) return true;
                return data.label.indexOf(value) !== -1;
            },
    //组织维护节点加载初始化
    loadDptNode (node, resolve) {
      if (node.level === 0) {
        this.loadfirstnode(resolve);
      }
      if (node.level >= 1 && node.isLeaf == false) {
        this.loadchildnode(node, resolve);
      }
    },
    //组织维护加载第一级节点
    loadfirstnode (resolve) {
      GetChildrenList().then((res) => {
        let topdpts = this.limit instanceof Array;
        if (topdpts == true && this.limit.length != 0) {
          resolve(this.limit);
        } else {
          const roledata = res.data.response;
          resolve(roledata);
        }
      });
    },
    //组织维护加载节点的子节点集合
    loadchildnode (node, resolve) {
      let para = { departmentId: node.data.Id };
      GetChildrenList(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    renderContent (h, { node, data, store }) {
      var icon = "";
      for (var i = 0; i < this.TypeIdModules.length; i++) {
        var tm = this.TypeIdModules[i];
        if (tm.Id == data.TypeId) {
          icon = tm.Icon;
          break;
        }
      }
      return (
        <span class="custom-tree-node">
          <i class={icon}>{node.label}</i>
        </span>
      )
      },
    // 查询
    getSearchData () {
      this.node2 = null;
      let limitIds = null;
      let topdpts = this.limit instanceof Array;
      if (topdpts == true && this.limit.length != 0) {
        limitIds = this.limit.map(function (item) {
          return item["Id"];
        });
      }
      //let paraobj = {
      //  DepartmentName: this.dptnameinput,
      //  UserCode: this.usernameinput,
      //  UserName: this.nameinput,
      //  LimitIds: limitIds,
      //  EnumPost: this.poststatus,
      //  Page: this.page2,
      //  Size: this.pageSize2
      //  };
        let paraobj = {
            deptName: this.filterText,
            username: this.usernameinput,
            name: this.nameinput,
            pageNum: this.page2,
            pageSize: this.pageSize2,
            LimitIds: limitIds,
            hasJob: this.poststatus,
        };
      GetPostUserViewPageByDto(paraobj).then((res) => {
          this.total2 = res.data.data.pages;
          this.totaldata2 = res.data.data.total;
          this.userform = res.data.data.list;
      });
    },
    handleCurrentChange (val) {
      this.page = val;
      this.getPreUsers();
      this.page = 1;
    },
    handleCurrentChange2 (val) {
      this.page2 = val;
      this.getSearchData();
      this.page2 = 1;
    },
    //查询主职务人员
    postsearch () {
      this.postshow = false;
      this.searchshow = true;
      this.getSearchData();
    },
    //职务树节点点击事件
    PostClick (node) {
      this.postshow = true;
      this.searchshow = false;
      this.deptname = node.Name;
        this.node2 = node;
        
      this.getPreUsers();
      },
      // Tree node 点击消息 
      nodeClickPost(node) {
          this.node21 = node;
          //console.log("nodeClickPost", node);
          let para = { deptId: node.id, pageNum: this.page2, pageSize: this.pageSize2, isAllNextDept: false,hasJob:1 };
          GetDepartmentPostUserList(para).then((res) => {

              if (res.data.data.list != null) {
                  this.totaldata = res.data.data.total;
                  //this.totaldata = res.data.data.pageNum;
                  this.userform = res.data.data.list;
              } else {
                  this.userform = [];
              }

              //if (res.data.data != null) {
              //    // console.log("res.data.data", res.data.data);
              //    this.postdata = res.data.data.job;
              //    console.log("this.postdata", this.postdata);
              //} else {
              //    this.postdata = null;
              //}
          });
      },

    getPreUsers () {
        let para;
        console.log("this.node21", this.node21)
      if (this.node21 != null) {
        let node3 = this.node21;
        para = {
            deptId: node3.id,
            pageNum: this.page,
            pageSize: this.pageSize,
            isAllNextDept: false, hasJob: 1
        };
      } else {
          para = {
              
            pageNum: this.page,
            pageSize: this.pageSize,
            isAllNextDept: false, hasJob: 1
        };
        }
      GetDepartmentPostUserList(para).then((res) => {
        if (res.data.code == 200) {
            this.total = res.data.data.total;
            this.totaldata = res.data.data.total;
            this.userform = res.data.data.list;
        } else {
          this.userform = [];
        }
      });
    },
    //从待选人员中选择人员
    addNewUser (row) {
      //console.log(row)
      let para = Object.assign({}, row);
      let check = this.data instanceof Array;
      let adduser = {
          Id: row.id,
        UserName: row.username,
        UserNameClaim: row.name,
          DepartmentId: row.deptId,
          DepartmentName: row.deptName,
      };
      if (this.gotuserform == null) {
        this.gotuserform = new Array();
        this.gotuserform.push(adduser);
      } else if (this.single && this.gotuserform.length == 1) {
        this.$message({
          message: '当前人员只能选一人',
          type: "error"
        });
      } else {
        try {
          this.gotuserform.forEach(item => {
            if (item.Id == adduser.Id) {

              throw new Error();
            }
          })
          this.gotuserform.push(adduser);
        } catch (e) {
          this.$message({
            message: '人员已选择',
            type: "info"
          });
        }


      }
    },
    //删除以选中职务人员
    deleteNewUser (row) {
      this.gotuserform.splice(
        this.gotuserform.indexOf(row),
        1);
    },
    //提交选中新人员弹框
    addNewSubmit () {
      var newdata = new Array();
      var dname = this.deptname;
      newdata = this.gotuserform;
      // console.log(this.gotuserform)
      // for (var i = 0; i < this.gotuserform.length; i++) {
      //   newdata.push(this.gotuserform[i].Id);
        // }
        console.log("callback", newdata)
      this.$emit("callback", newdata); //将值传给父组件
    },
    diaclose () {
      this.datatree = false;
      this.userform = [];
      this.usernameinput = null;
      this.nameinput = null;
      // this.poststatus = null;
      this.total = null;
      this.totaldata = null;
      this.pageSize = 5;
      this.currentPage = 1;
      this.deptname = null;
    }
  },
  mounted () {
    //  GetDepartmentTypes().then((res) => {
    //      if (res.data != null)
    //        this.TypeIdModules = res.data.response.data;
    //});
    let check = this.data instanceof Array;
    let para;
    if (this.all) {
      this.alluserradioshow = true;
    } else {
      this.alluserradioshow = false;
    }
    this.userform = [];
    this.datatree = true;
    if (check == true && this.data.length != 0) {
      para = this.data;
    } else {
      para = [];
    }
    //GetUserChooseList(para).then((res) => {
    //  this.gotuserform = res.data.response;
    //});
      //let para = { departmentId: "" };
      GetChildrenList().then((res) => {
          console.log("mounted_GetChildrenList:", res)
          this.departmentsearchdata = res.data.data.dept;
      });
      //this.postsearch();
  },
  watch: {   //监听值变化
    data: function () {

      },
      filterText(val) {
          this.$refs.tree.filter(val);
      }
  }
}
</script>
<style scoped>
/deep/ .el-table__body tr.current-row > td {
  background: #fabe64 !important;
}

.el-col
  >>> .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background-color: #fabe64;
}

.el-col >>> .el-tree-node__expand-icon.expanded {
  -webkit-transform: rotate(0deg) !important;
  transform: rotate(0deg) !important;
}
.el-col >>> .el-icon-caret-right:before {
  content: url('../assets/add.png') !important;
  font-size: 16px;
}
.el-col >>> .expanded:before {
  content: url('../assets/cancel.png') !important;
  font-size: 16px;
}
.el-col >>> .is-leaf.el-tree-node__expand-icon.el-icon-caret-right:before {
  content: '' !important;
  font-size: 16px;
}

.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}

#userdiv {
  height: calc(100vh - 350px);
}
.el-tree {
  min-width: 100%;
  font-size: 14px;
  display: inline-block;
}
#dpttree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
#usertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  /*width:200px;*/
  border: 0px solid blue;
}
#chooseusertable {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 440px);
  width: 100%;
  /*width:200px;*/
  border: 0px solid blue;
}

/* .el-scrollbar .el-scrollbar__wrap {overflow-x: hidden;}
.el-tree>.el-tree-node{

height: 350px; 
min-width: 100%;
display:inline-block;
} */
</style>