<template>
  <section class="new_css">
    <el-row class="new_header">
      <el-col
        v-if="buttonList.length > 0"
        :span="24"
        class="organizetoolbar"
        style="padding-bottom: 0px"
      >
        <el-form :inline="true" ref="selectForm" :model="selectForm" class="select_form">
          <el-form-item label="姓名:">
            <el-input v-model="selectForm.name"></el-input>
          </el-form-item>
          <el-form-item label="缴费单位:">
            <el-select v-model="selectForm.PaymentAccount" placeholder="请选择缴费单位" style="width: 100%">
              <el-option label="格瑞德集团" value="格瑞德集团"></el-option>
              <el-option label="冷却塔公司" value="冷却塔公司"></el-option>
              <el-option label="格瑞德环境科技有限公司" value="格瑞德环境科技有限公司"></el-option>
              <el-option label="格瑞德环保科技有限公司" value="格瑞德环保科技有限公司"></el-option>
              <el-option label="山东格瑞德人工环境产业设计研究院有限公司" value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
              <el-option label="山东格物智联信息技术有限公司" value="山东格物智联信息技术有限公司"></el-option>
              <el-option label="淄博格瑞德炭净环保有限公司" value="淄博格瑞德炭净环保有限公司"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="增员日期:">
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="selectForm.dateStart" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="11">
              <el-date-picker type="date" placeholder="选择日期" v-model="selectForm.dateEnd" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-col>
          </el-form-item>
          <toolbar :buttonList="buttonList" @callFunction="callFunction" :buttonListmsg="buttonListmsg"></toolbar>
        </el-form>
      </el-col>
    </el-row>
    <div class="calc-height">
      <!--列表-->
      <el-table
        :data="users"
        highlight-current-row
        height="100%"
      
        @row-dblclick="handleShow"
     
        @current-change="selectCurrentRow"
        class="new_table"
      >
        <el-table-column prop="UserName" label="名称"   width="100"> </el-table-column>
        <el-table-column
          label="缴费级别"
           width="100"
          prop="MonthLevelName"
        ></el-table-column>
         <el-table-column
         width="200"
          label="身份证号"
          prop="IDCard"
        ></el-table-column>
        <el-table-column label="缴费基数" prop="BaseSalary"></el-table-column>
        <el-table-column label="缴费单位" prop="PaymentAccount"></el-table-column>
        <el-table-column
          label="增员日期"
          prop="AddDate"
          :formatter="formatStartTime"
        ></el-table-column>
        <el-table-column
          label="增员原因"
          prop="AddReason"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="参加工作日期"
          prop="WorkDate"
          :formatter="formatStartTime"
        ></el-table-column>
      </el-table>
    </div>
    <!-- 分页器 -->
    <el-pagination
      align="center"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-size="pageSize"
      layout="prev, pager, next, jumper ,total"
      :page-count="total"
      :total="totaldata"
    >
    </el-pagination>
    <el-dialog
      :title="operation ? '社保增员' : '编辑'"
      :visible.sync="SakaryVisible"
      v-model="SakaryVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="SakaryForm"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="增员人:"
              prop="UserName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-input
                type="text"
                :disabled="true"
                v-model="SakaryForm.UserName"
              >
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="chooseUser"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="缴费级别:"
              prop="MonthLevelName"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-select
                v-model="SakaryForm.MonthLevelName"
                placeholder="缴费级别"
                @change="CertTypeModel($event)"
                style="width: 100%"
              >
                <el-option
                  v-for="item in CertTypeLists"
                  :key="item.Id"
                  :label="item.Name"
                  :value="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item
              label="添加时间:"
              prop="AddDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.AddDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              label="工作时间:"
              prop="WorkDate"
              :rules="[
                { required: true, message: '不能为空', trigger: 'blur' },
              ]"
            >
              <el-date-picker
                type="date"
                v-model="SakaryForm.WorkDate"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="12">
            <el-form-item label="缴费单位:" prop="PaymentAccount">
                <el-select v-model="SakaryForm.PaymentAccount"
                           placeholder="缴费单位"
                           style="width: 100%">
                    <el-option label="格瑞德集团"
                               value="格瑞德集团"></el-option>
                    <el-option label="冷却塔公司"
                               value="冷却塔公司"></el-option>
                    <el-option label="格瑞德环境科技有限公司"
                               value="格瑞德环境科技有限公司"></el-option>
                    <el-option label="格瑞德环保科技有限公司"
                               value="格瑞德环保科技有限公司"></el-option>
                    <el-option label="山东格瑞德人工环境产业设计研究院有限公司"
                               value="山东格瑞德人工环境产业设计研究院有限公司"></el-option>
                    <el-option label="山东格物智联信息技术有限公司"
                               value="山东格物智联信息技术有限公司"></el-option>
                    <el-option label="淄博格瑞德炭净环保有限公司"
                               value="淄博格瑞德炭净环保有限公司"></el-option>
                </el-select>
             
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="缴费基数:">
              <el-input
                type="text"
                v-model="SakaryForm.BaseSalary"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="增员原因:" prop="AddReason">
              <el-input type="text" v-model="SakaryForm.AddReason"> </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="养老保险(公司):">
              <el-input type="text" v-model="SakaryForm.Factory1" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="医疗保险(公司):" prop="Factory2">
              <el-input
                type="text"
                v-model="SakaryForm.Factory2"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="失业保险(公司):" prop="Factory3">
              <el-input type="text" v-model="SakaryForm.Factory3" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工伤保险(公司):" prop="Factory4">
              <el-input
                type="text"
                v-model="SakaryForm.Factory4"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="生育保险(公司):" prop="Factory5">
              <el-input type="text" v-model="SakaryForm.Factory5" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="养老保险(个人):" prop="User1">
              <el-input
                type="text"
                v-model="SakaryForm.User1"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="医疗保险(个人):" prop="User2">
              <el-input type="text" v-model="SakaryForm.User2" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失业保险(个人):" prop="User3">
              <el-input
                type="text"
                v-model="SakaryForm.User3"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="工伤保险(个人):" prop="User4">
              <el-input type="text" v-model="SakaryForm.User4" disabled>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生育保险(个人):" prop="User5">
              <el-input
                type="text"
                v-model="SakaryForm.User5"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="operation1">
          <el-col :span="24" style="text-align: center">
         
              <el-button type="primary" v-on:click="onSubmit('SakaryForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
      title="社保减员"
      :visible.sync="InsuranVisible"
      v-model="InsuranVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="SakaryForm"
        :model="InsuranForm"
        class="demo-form-inline"
        label-width="100px"
      >
        <el-form-item
          label="减员时间:"
          prop="ReduceDate"
          :rules="[{ required: true, message: '不能为空', trigger: 'blur' }]"
        >
          <el-date-picker
            type="date"
            v-model="InsuranForm.ReduceDate"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
          ></el-date-picker>
        </el-form-item>

        <el-form-item label="减员原因:" prop="ReduceReason">
          <el-input
            type="textarea"
            v-model="InsuranForm.ReduceReason"
          ></el-input>
        </el-form-item>

        <el-row>
          <el-col :span="24" style="text-align: center">
           
              <el-button
                type="primary"
                v-on:click="onInsuranFormSubmit('InsuranForm')"
                >提交</el-button
              >
         
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
    <el-dialog
    title="选择人员"
      :visible.sync="addUserVisible"
      v-model="addUserVisible"
      width="75%"
      center
      append-to-body
    >
      <UserChoose
        :data="choosedusers"
        :all="true"
        :single="false"
        @callback="usercallFunction"
      ></UserChoose>
    </el-dialog>
  </section>
</template>
<script>
import util from "../../../util/date";
import * as validate from "../../../util/validate";
import UserChoose from "../../components/UserChoose";
import {
  GetInsurancePersonPageList,
  GetDicInsurancePageList,
  AddInsurancePerson,
  AddReduceInfo,
  UpdateInsurancePerson,
} from "../../api/hr";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/newToolbar";
export default {
  components: { Toolbar, UserChoose },
  data() {
    return {
      loading: true,
      searchVal: "",
      selectForm: {
        name: "",
        PaymentAccount:"",
        // dateStart:new Date().getFullYear()-1+"-01-01" ,
        dateStart:null,
        dateEnd:new Date(),
        // dateStart:null,
        // dateEnd:null,
      },
      choosedusers: [],
      CertTypeLists: [],
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      InsuranVisible: false,
      SakaryVisible: false,
      buttonListmsg: "",
      users: [],
      operation: false, // true:新增, false:编辑
      operation1: true,
      buttonList: [],
      addUserVisible: false,
      SakaryForm: {
        UserId: "",
        UserName: "",
        WorkDate: "",
        AddDate: "",
        AddReason: "",
        MonthLevelId: "",
        MonthLevelName: "",
        Name: "",
        BaseSalary: "",
        Factory1: "",
        Factory2: "",
        Factory3: "",
        Factory4: "",
        Factory5: "",
        User1: "",
        User2: "",
        User3: "",
        User4: "",
        User5: "",
      },
      InsuranForm: {
        UserId: "",
        ReduceDate: "",
        ReduceReason: "",
        BaseSalary: "",
      },
      filters: {
        name: "",
      },
    };
  },
  methods: {
    selectCurrentRow(val) {
      this.currentRow = val;
    },
    callFunction(item) {
      this[item.Func].apply(this, item);
    },
    chooseUser() {
      this.addUserVisible = true;
    },
    usercallFunction(newdata) {
      if (newdata.length > 1) {
        this.$message({
          message: "不允许选择多人",
          type: "error",
        });
        return;
      }
      console.log(newdata);

      this.SakaryForm.UserId = newdata[0].Id;
      this.SakaryForm.UserName = newdata[0].UserNameClaim;
      this.addUserVisible = false;
    },
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.page = val;
      this.getData(false);
    },
    //获取列表
    getData(isCurrent = true) {
      if (isCurrent) {
        this.page = 1;
      }
      let para = {
        page: this.page,
        size: this.pageSize,
        userName: this.selectForm.name,
        paymentAccount:this.selectForm.PaymentAccount,
        timeStart:this.selectForm.dateStart,
        timeEnd:this.selectForm.dateEnd,
      };
      console.log(para);
      GetInsurancePersonPageList(para).then((res) => {
        console.log(res);
        this.total = res.data.response.pageCount;
        this.totaldata = res.data.response.dataCount;
        this.users = res.data.response.data;
        this.loading = false;
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
    handleEdit() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }
      GetDicInsurancePageList().then((res) => {
        this.CertTypeLists = res.data.response.data;
        this.CertTypeModel(row.MonthLevelName);
      });

      this.SakaryForm = Object.assign({}, row);

      (this.operation = false), (this.operation1 = true);
      this.SakaryVisible = true;
    },
    handleShow() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要查看的一行数据！",
          type: "error",
        });
        return;
      }

      GetDicInsurancePageList().then((res) => {
        this.CertTypeLists = res.data.response.data;
        this.CertTypeModel(row.MonthLevelName);
      });

      this.SakaryForm = Object.assign({}, row);

      (this.operation = false), (this.operation1 = false);
      this.SakaryVisible = true;
    },
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });
        return;
      }

      this.InsuranForm.UserId = row.UserId;
      this.InsuranForm.BaseSalary = row.BaseSalary;
      this.InsuranVisible = true;
    },
    handleAdd() {
      (this.SakaryForm = {
        UserId: "",
        UserName: "",
        WorkDate: "",
        AddDate: "",
        AddReason: "",
        MonthLevelId: "",
        MonthLevelName: "",
        Name: "",
        BaseSalary: "",
        Factory1: "",
        Factory2: "",
        Factory3: "",
        Factory4: "",
        Factory5: "",
        User1: "",
        User2: "",
        User3: "",
        User4: "",
        User5: "",
      }),
        GetDicInsurancePageList().then((res) => {
          this.CertTypeLists = res.data.response.data;
        });
      (this.operation = true), (this.SakaryVisible = true);
    },
    CertTypeModel(id) {
      console.log(id);
      console.log(this.CertTypeLists);
      let obj = {};
      obj = this.CertTypeLists.find((item) => {
        //model就是上面的数据源
        console.log(item);
        return item.Name === id; //筛选出匹配数据
      });
      console.log(obj);
      this.SakaryForm.MonthLevelId = obj.Id;
      this.SakaryForm.BaseSalary = obj.BaseSalary;
      this.SakaryForm.Factory1 = obj.Factory1;
      this.SakaryForm.Factory2 = obj.Factory2;
      this.SakaryForm.Factory3 = obj.Factory3;
      this.SakaryForm.Factory4 = obj.Factory4;
      this.SakaryForm.Factory5 = obj.Factory5;
      this.SakaryForm.User1 = obj.User1;
      this.SakaryForm.User2 = obj.User2;
      this.SakaryForm.User3 = obj.User3;
      this.SakaryForm.User4 = obj.User4;
      this.SakaryForm.User5 = obj.User5;
    },
    inputMoney(name) {
      if (!validate.validateIsMoney(this.SakaryForm[name])) {
        this.$message({
          message: "请输入金额",
          type: "error",
          duration: 5000,
        });
        this.SakaryForm[name] = null;
        return;
      }
    },
    onSubmit(formName) {
      this.$confirm("确定要提交吗？", "提示", {}).then(() => {
        this.$refs[formName].validate((valid) => {
          let para = Object.assign({}, this.SakaryForm);
          console.log(para);
          if (this.operation) {
            //新增
            AddInsurancePerson(para).then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "添加成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData();
              } else {
                this.$message({
                  message: "添加失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          } else {
            //修改
            UpdateInsurancePerson(para).then((res) => {
              console.log(res);
              if (res.data.success) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                  duration: 5000,
                });
                this.SakaryVisible = false;
                this.getData(false);
              } else {
                this.$message({
                  message: "修改失败",
                  type: "error",
                  duration: 5000,
                });
              }
            });
          }
        });
      });
    },
    onInsuranFormSubmit(formName) {
      let para = Object.assign({}, this.InsuranForm);
      AddReduceInfo(para).then((res) => {
        console.log(res);
        if (res.data.success) {
          this.$message({
            message: "减员成功",
            type: "success",
            duration: 5000,
          });
          this.InsuranVisible = false;
          this.getData();
        } else {
          this.$message({
            message: "减员失败",
            type: "error",
            duration: 5000,
          });
        }
      });
    },
  },
  mounted() {
    this.getData();
    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    let buttons = window.localStorage.buttList
      ? JSON.parse(window.localStorage.buttList)
      : [];
    this.buttonList = getButtonList(this.$route.path, routers, buttons);
  },
};
</script>
<style scoped>
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
</style>

